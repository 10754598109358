var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"player@2.9.1-alpha.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/apps/prod/71/player";

import * as Sentry from '@sentry/nextjs';
import {
  beforeBreadcrumb,
  denyUrls,
  playerBeforeSend,
  commonErrorToIgnore,
} from 'shared/src/utils/sentry';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '_DSN_',
  enableTracing: true,
  tracesSampleRate: 0.25,
  integrations: [
    new Sentry.BrowserTracing({
      traceFetch: true,
      enableHTTPTimings: true,
      startTransactionOnLocationChange: true,
      traceXHR: true,
      shouldCreateSpanForRequest(request) {
        return (
          request?.url?.match(/api/) ||
          request?.url?.match(/graphql/) ||
          request?.url?.match(/api\/graphql/)
        );
      },
      markBackgroundTransactions: true,
      startTransactionOnPageLoad: true,
      enableLongTask: true,
      idleTimeout: 1000,
      _experiments: {
        enableInteractions: true,
        enableLongTask: true,
      },
    }),
    new Sentry.GlobalHandlers(),
    new Sentry.TryCatch(),
    new Sentry.Dedupe(),
  ],
  attachStacktrace: true,
  autoSessionTracking: true,
  enabled: process.env.NEXT_PUBLIC_SENTRY_DSN !== undefined,
  debug: false,
  environment: process.env.NODE_ENV,
  profilesSampleRate: 1.0,
  sendClientReports: true,
  replaysSessionSampleRate: false,
  replaysOnErrorSampleRate: 1.0,
  instrumenter: 'sentry',
  beforeBreadcrumb,
  ignoreErrors: commonErrorToIgnore,
  denyUrls,
  beforeSend: playerBeforeSend,
});

export default Sentry;
