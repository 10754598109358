import { createContext, useContext, useMemo } from 'react';
import { useMobile } from 'shared/src/hooks/useMobile';

interface MobileContextType {
  isMobile: boolean;
  isLandscapeView: boolean;
  userAgent?: string;
}

const MobileContext = createContext<MobileContextType>({
  isMobile: false,
  isLandscapeView: false,
  userAgent: '',
});

export const useSSRMobile = () => useContext(MobileContext);

export const AppProvider = ({
  children,
  userAgent,
}: {
  children: React.ReactNode;
  userAgent: MobileContextType['userAgent'];
}) => {
  const { isLandscapeView, isMobile } = useMobile(userAgent);

  const value: MobileContextType = useMemo(
    () => ({
      isMobile,
      isLandscapeView,
      userAgent,
    }),
    [isMobile, isLandscapeView, userAgent]
  );

  return (
    <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
  );
};
